<template>
  <div>
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
      rounded="sm"
    >
      <comprasForm
        :compra="compraData"
        compraSubmit="Crear OC"
        @processCompra="agregarCompras"
      />
    </b-overlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const comprasForm = () => import('@/layouts/components/Compras/ComprasForm.vue')

export default {
  components: {
    BOverlay,
    comprasForm,
  },
  data() {
    return {
      spinner: false,
      compraData: {
        fecha: new Date(),
        valorTotal: 0,
        idProveedor: null,
        idUsuario: null,
        solicitadoPor: null,
        numCotizacion: null,
        tiempoGarantia: null,
        observaciones: null,
        lugarEntrega: null,
        condicionPago: null,
        fechaEntrega: null,
        comprasProductos: [],
      },
    }
  },
  watch: {
    getUserAuth() {
      this.idUsuario = this.getUserAuth.id
    },
  },
  methods: {
    ...mapActions({ createCompras: 'compras/addCompras' }),
    agregarCompras(compra) {
      this.spinner = true
      this.createCompras(compra).then(() => {
        const errorCompras = store.state.compras
        const errorMessage = errorCompras.errorMessage.errors
        if (!errorCompras.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Compra creada con éxito 👍',
              icon: 'CheckIcon',
              text: `La compra "${compra.referencia}" fue creada con éxito!`,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'compras',
          })
        } else if (errorMessage.rut) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.rut[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
